<template>
  <div>
    <div class="d-flex flex-md-row mt-2 flex-column-reverse">
      <div class="col-md-9 nopadding">
        <no-access-info-card v-if="showNoAccessInfoCard" />
        <div v-else>
          <div v-if="jobs.length">
            <job-card
              v-for="(job, index) in jobs"
              :key="job.id"
              :job="job"
              :is_past_lable_active="Boolean((actualJobsCount && actualJobsCount === index) || (!actualJobsCount && index === 0))"
              :can-show-image="showImages"
              @onImgLoad="loadedImages+= 1"
            />
            <div
              v-if="pagination"
              class="d-flex align-items-center justify-content-center"
            >
              <b-pagination
                v-if="pagination.total > pagination.per_page"
                v-model="pageNumber"
                :per-page="pagination.per_page"
                :total-rows="pagination.total"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
          <div
            v-if="!jobs || !jobs.length"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="@/assets/images/pages/course/empty-list.svg"
              alt="Buypass"
              class="d-inline-block mt-5"
            />
          </div>
        </div>
      </div>
      <div class="col-md-3 pl-md-1 pr-md-0 p-0">
        <b-input-group class="input-group-merge mb-1">
          <b-input-group-prepend
            class="no-border"
            is-text
          >
            <feather-icon icon="SearchIcon" />
          </b-input-group-prepend>

          <b-form-input
            v-model="search"
            type="search"
            class="no-border"
            :placeholder="$t('Search...')"
          />
        </b-input-group>
        <b-card body-class="p-1">
          <div>
            <h4 class="font-weight-bolder">
              {{ $t("Old jobs") }}
            </h4>
            <b-form-checkbox
              v-model="showOldJobs"
              class="custom-control-primary mt-1"
            >
              <h5>{{ $t('Hide archived') }}</h5>
            </b-form-checkbox>
          </div>
        </b-card>
        <filter-card page="jobs" />
      </div>
    </div>
  </div>
</template>
<script>
import {
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BPagination,
  BImg,
  BCard,
  BFormCheckbox,
} from 'bootstrap-vue'
import { debounce } from 'vue-debounce'

import FilterCard from '@/components/course/FilterCard.vue'
import JobCard from '@/components/jobs/JobCard.vue'
import NoAccessInfoCard from '@/components/NoAccessInfoCard.vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BPagination,
    BImg,
    BCard,
    BFormCheckbox,

    FilterCard,
    JobCard,
    NoAccessInfoCard,
  },
  data() {
    return {
      loadedImages: 0,
    }
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters['auth/isAuthenticated']
    },
    showImages() {
      return this.loadedImages >= this.imagesCount
    },
    userPermissions() {
      return this.$store.getters['auth/permissions']
    },
    jobs() {
      return this.$store.getters['jobs/jobs']
    },
    actualJobsCount() {
      return this.$store.getters['jobs/actualJobsCount']
    },
    showOldJobs: {
      get() {
        return this.$store.getters['jobs/showOldJobs']
      },
      set(value) {
        this.$store.commit('jobs/setShowOldCourse', value)
      },
    },
    search: {
      get() {
        return this.$store.getters['jobs/search']
      },
      // eslint-disable-next-line
      set: debounce(function (search) {
        this.$store.commit('jobs/setSearch', search)
      }, 500),
    },
    pageNumber: {
      set(pageNumber) {
        this.$store.commit('jobs/setPageNumber', pageNumber)
      },
      get() {
        return this.$store.getters['jobs/pageNumber']
      },
    },
    query() {
      return this.$store.getters['jobs/query']
    },
    pagination() {
      return this.$store.getters['jobs/pagination']
    },
    imagesCount() {
      let counter = 0
      if (this.jobs) {
        this.jobs.forEach(job => {
          counter += this.checkForImage(job)
        })
      }
      return counter
    },
    user() {
      return this.$store.getters['auth/user']
    },
    representACompanyAdmin() {
      return 'id' in this.user.active_business_profile && this.user.active_business_profile.role === 'admin'
    },
    showNoAccessInfoCard() {
      if (!this.isAuthenticated) return false
      if (this.representACompanyAdmin) return false
      if (!this.checkPermission('JOBS_READ', this.userPermissions)) return true
      return false
    },
  },
  watch: {
    // eslint-disable-next-line
    "$route.query.filter": {
      handler() {
        if (!this.showNoAccessInfoCard) {
          this.loadedImages = 0
          this.$store.dispatch('jobs/fetchJobs')
        }
      },
      deep: true,
    },
    query: {
      handler(query) {
        this.updateQuery(query)
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('specialties/fetchSpecialties')
    this.$store.dispatch('filterData/fetchCounties')
    if (this.$route.query.filter) {
      this.$store.commit('jobs/setQuery', JSON.parse(this.$route.query.filter))
    }
    if (!this.showNoAccessInfoCard) {
      this.$store.dispatch('jobs/fetchJobs')
    }
  },
  beforeDestroy() {
    this.$store.commit('jobs/clearFilters')
  },
  methods: {
    updateQuery(query) {
      this.$router.push({
        path: '/jobs',
        query: { filter: JSON.stringify(query) },
      }).catch(() => {})
    },
    checkForImage(job) {
      let workplace = null
      if (job.attributes.workplace_situation.workplace_situation_group === 'general_practice') {
        workplace = job.attributes.workplace_situation.workplace_situation.clinic
      }
      if (job.attributes.workplace_situation.workplace_situation_group === 'hospital') {
        const keys = Object.entries(job.attributes.workplace_situation.workplace_situation)
        keys.forEach(([key]) => {
          workplace = job.attributes.workplace_situation.workplace_situation[key]
        })
      }
      if (job.attributes.workplace_situation.workplace_situation_group === 'other') {
        workplace = job.attributes.workplace_situation.workplace_situation.custom_workplace
      }
      if (workplace && workplace.image) return 1
      return 0
    },
  },
}
</script>
