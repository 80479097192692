<template>
  <div>
    <div
      v-if="is_past_lable_active"
      class="
        past-divider
        d-flex
        align-items-center
        justify-content-between
        mb-2
      "
    >
      <div />
      <h5 class="mb-0">
        {{ $t("Past jobs") }}
      </h5>
      <div />
    </div>
    <b-card
      style="max-width: 100%"
      class="text-center slide-right"
      @click="openJobDetails"
    >
      <div class="d-flex flex-md-row flex-column align-items-center">
        <div
          class="
            col-md-4 col-xl-3
            px-0
            pr-md-2
            d-flex
            justify-content-center
            card-image-container
            align-items-center align-self-center
          "
        >
          <div v-if="workplace && workplace.image">
            <img
              v-show="canShowImage"
              :src="workplace.image"
              alt="Image"
              class="rounded job-card-image job-logo-max-height"
              @load="onImgLoad"
            >
          </div>
          <img
            v-if="!(canShowImage && workplace && workplace.image)"
            class="rounded job-card-image job-logo-max-height"
            src="@/assets/images/cards/job_card_image.png"
            alt=""
          >
        </div>
        <div
          class="
            col-md-8 col-xl-9
            px-0
            align-self-md-stretch
            d-flex
            flex-column
          "
        >
          <div
            class="
              flex-column flex-md-row
              d-flex
              justify-content-between
              align-items-start
              m-0
              p-0
              w-100
            "
          >
            <div class="col-md-8 pl-0">
              <h4
                class="
                  font-weight-bolder
                  text-dark text-md-left
                  w-100
                  mt-1 mt-md-0
                "
              >
                {{ job.attributes.title }}
              </h4>
              <h6 class="text-primary text-md-left text-center">
                {{
                  `${workplace ? workplace.name + " |" : ""} ${
                    job.attributes.rate
                  }% ${$t("stilling")} | ${types[job.attributes.type]}`
                }}
                <!-- <h6
                  v-if="job.attributes.job_categories.length"
                  class="d-inline text-primary"
                >
                  |
                </h6>
                <h6
                  v-for="tag in translate(job.attributes.job_categories, $i18n.locale)"
                  :key="tag.id"
                  class="d-inline text-primary"
                >
                  {{ tag.name }}&nbsp;
                </h6> -->
              </h6>
            </div>
            <div class="col-md-4 text-md-right px-0">
              <h5 class="text-primary font-weight-bold">
                {{ job.attributes.application_deadline | transformDate }}
              </h5>
              <h5 class="text-muted mb-0">
                {{ $t("Application deadline") }}
              </h5>
            </div>
          </div>
          <!-- <div
            class="
              flex-column flex-lg-row
              d-flex
              justify-content-between
              align-items-start
              m-0
              p-0
              w-100
            "
          >
            <div
              class="text-left col-md-8 col-12 px-0 ellipsis-2-line description-no-style"
              v-html="job.attributes.description"
            />
          </div> -->
          <div
            class="
              d-flex
              justify-content-between
              align-items-center
              mt-auto
              pt-1
            "
          >
            <div
              v-if="job.attributes.specialties.length"
              class="row m-0 p-0 align-items-center align-self-center"
            >
              <h5
                v-for="tag in translate(
                  job.attributes.job_categories,
                  $i18n.locale
                )"
                :key="tag.id + 'cat'"
                class="mr-1 mt-10 mb-0"
              >
                <b-badge
                  class="text-dark font-weight-normal"
                  variant="secondary"
                >
                  {{ tag.name }}
                </b-badge>
              </h5>
              <h5
                v-for="tag in translate(
                  job.attributes.specialties,
                  $i18n.locale
                )"
                :key="tag.id + 'spec'"
                class="mr-1 mt-10 mb-0"
              >
                <b-badge
                  class="text-dark font-weight-normal"
                  variant="secondary"
                >
                  {{ tag.name }}
                </b-badge>
              </h5>
            </div>
            <div class="ml-auto">
              <div class="text-right">
                <div v-if="is_own && !isViewer">
                  <b-button
                    variant="outline-primary"
                    class="mb-1"
                    @click.stop="$router.push(`/jobs/update/${job.id}`)"
                  >
                    {{ $t("Edit") }}
                  </b-button>
                </div>
                <h5 class="text-muted mb-0">
                  {{ job.attributes.created_at | transformDate }}
                </h5>
                <h5 class="text-muted mb-0">
                  {{ $t("Published") }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BCard, BBadge, BButton } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
  },
  filters: {
    transformDate(obj) {
      return moment(obj).format('DD.MM.YYYY')
    },
  },
  props: {
    // eslint-disable-next-line
    job: {
      type: Object,
    },
    is_own: {
      type: Boolean,
      default: false,
    },
    is_past_lable_active: {
      type: Boolean,
      default: false,
    },
    canShowImage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      types: {
        full_time: this.$t('Full time'),
        substitude: this.$t('Substitude'),
      },
    }
  },
  computed: {
    workplace() {
      let workplace = null
      if (!this.job.attributes.workplace_situation) return null
      if (
        this.job.attributes.workplace_situation.workplace_situation_group
        === 'general_practice'
      ) {
        workplace = this.job.attributes.workplace_situation.workplace_situation
          .clinic
      }
      if (
        this.job.attributes.workplace_situation.workplace_situation_group
        === 'hospital'
      ) {
        const keys = Object.entries(
          this.job.attributes.workplace_situation.workplace_situation,
        )
        keys.forEach(([key]) => {
          workplace = this.job.attributes.workplace_situation
            .workplace_situation[key]
        })
      }
      if (
        this.job.attributes.workplace_situation.workplace_situation_group
        === 'other'
      ) {
        workplace = this.job.attributes.workplace_situation.workplace_situation
          .custom_workplace
      }
      return workplace
    },
    user() {
      return this.$store.getters['auth/user']
    },
    isViewer() {
      return this.user.active_business_profile.role === 'viewer'
    },
  },
  methods: {
    workplaceSetter() {
      let workplace = null
      if (
        this.job.attributes.workplace_situation.workplace_situation_group
        === 'general_practice'
      ) {
        workplace = this.job.attributes.workplace_situation.workplace_situation
          .clinic
      }
      if (
        this.job.attributes.workplace_situation.workplace_situation_group
        === 'hospital'
      ) {
        const keys = Object.entries(
          this.job.attributes.workplace_situation.workplace_situation,
        )
        keys.forEach(([key]) => {
          workplace = this.job.attributes.workplace_situation
            .workplace_situation[key]
        })
      }
      if (
        this.job.attributes.workplace_situation.workplace_situation_group
        === 'other'
      ) {
        workplace = this.job.attributes.workplace_situation.workplace_situation
          .custom_workplace
      }
      return workplace
    },
    openJobDetails() {
      if (this.is_own) {
        this.$router.push(`/my_jobs/details/${this.job.id}`)
      } else {
        this.$router.push(`/jobs/details/${this.job.id}`)
      }
    },
    onImgLoad() {
      this.$emit('onImgLoad')
    },
  },
}
</script>
